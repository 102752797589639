.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .logo {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #02141e;
  }
  
  .loader-tabs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loader-tab {
    width: 40px;
    height: 6px;
    background-color: orange;
    animation: loadingAnimation 2s infinite;
    opacity: 0; /* Initially hide the bars */
    margin-top: 5px;
    margin-bottom: 5px; /* Add space between tabs */
  }
  
  .loader-tab:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loader-tab:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .loader-tab:nth-child(3) {
    animation-delay: 1s;
  }
  
  @keyframes loadingAnimation {
    0%, 25% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
  }